<template>
  <div class="speaking-sentence-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    />
    <div class="game-content">
      <div class="menu" v-if="hasMenu">
        <SceneSwitchThumbnails
          :buttons="buttonList"
          :numBers="currentIndex"
          @changeTheNumbers="changeTheNumbers"
          :smallMenu="hasSmallMenu"
        />
      </div>
      <div class="title-area" v-if="titleInfo" :class="'lesson-' + lesson">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="bgImg-area">
        <template v-for="(item, index) in gameList">
          <div
            class="img-box"
            :class="{hasPointer: item.pinyin || hasPointer}"
            :key="index"
            v-if="index + 1 === currentIndex"
            @click="handleClickImg(index)"
          >
            <transition name="el-fade-in">
              <img :src="item.bgImg" alt="" />
            </transition>
          </div>
        </template>
      </div>

      <div
        class="bottom-sentence-area"
        :class="{ higherPosition: lesson == '14' }"
        v-if="hasSentence"
      >
        <div
          class="hanzi-item"
          v-for="(item, index) in gameList"
          :key="index"
          v-show="item.isShowSentence"
          :class="{ blueBG: isBlueBackground }"
        >
          <div class="pinyin sentence-hanzi font-pinyin-medium">
            {{ item.pinyin }}
          </div>
          <div class="sentence-hanzi font-hanzi-medium">{{ item.hanzi }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
export default {
  name: "SpeakingSentence",
  props: {
    gameList: {
      type: Array,
      require: true,
      default: () => [],
    },
    buttonList: {
      type: Array,
      require: true,
      default: () => [],
    },
    titleInfo: {
      type: Object,
      default: () => {},
    },
    isBlueBackground: {
      type: Boolean,
      default: false,
    },
    isClick: {
      type: Boolean,
      default: true,
    },
    lesson: {
      type: Number,
      require: false,
    },
    isTwoImg: {
      type: Boolean,
      default: false,
    },
    isThreeImg: {
      type: Boolean,
      default: false,
    },
    hasMenu: {
      type: Boolean,
      default: true,
    },
    hasSentence: {
      type: Boolean,
      default: true,
    },
    isClickToShowSentence: {
      type: Boolean,
      default: false,
    },
    isDrawingConer: {
      type: Boolean,
      default: false,
    },
    hasSmallMenu: {
      type: Boolean,
      default: false,
    },
    hasPointer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentIndex: 1,
      clickIndex: 1,
      isLastStep: false,
      clickMenuSocketInfo: {},
      clickImgSocketInfo: {},
      lock: false,
      isFirstClick: true,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: this.isDrawingConer ? "draw" : "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  watch: {
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004601,
          data: { value },
          text: "SpeakingSentence点击Menu socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004602,
          data: { value },
          text: "SpeakingSentence点击Img socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("speakingSentenceMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
    this.$bus.$on("clickSpeakingSentenceImg", ({ index }) => {
      this.handleClickImg("socket", index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("speakingSentenceMenu");
    this.$bus.$off("clickSpeakingSentenceImg");
  },

  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      console.log(index);
      this.$emit("initGame");
      this.lock = false;
      this.clickIndex = 1;
      this.currentIndex = index;
      this.isFirstClick = true;
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      if (!this.isClick && index === this.gameList.length) {
        this.isLastStep = true;
      } else {
        this.isLastStep = false;
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickImg(index, socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          value: Math.random(),
        };
      }
      if (!this.hasMenu) {
        this.lock = false;
        if (this.currentIndex == this.gameList.length) {
          return;
        }
        this.currentIndex++;
      }
      if (!this.isClick) {
        return;
      }
      console.log(this.lock);
      if (!this.isClickToShowSentence) {
        this.$emit("showSentence", this.currentIndex);
      } else {
        if (!this.isFirstClick) {
          console.log("++++++++++++++++");

          this.$emit("showSentence", this.currentIndex);
        } else {
          this.isFirstClick = false;
        }
      }
      if (this.lock) {
        return;
      }
      playCorrectSound(true, false);
      if (!this.isThreeImg) {
        this.lock = true;
      }
      if (this.isTwoImg) {
        this.$emit("showSecondImg", this.currentIndex);
      }
      if (this.isThreeImg) {
        this.$emit("showThirdImg", this.currentIndex, this.clickIndex);
        this.clickIndex++;
        if (this.clickIndex === 3) {
          this.lock = true;
        }
      }

      if (this.isThreeImg) {
        if (
          this.currentIndex === this.gameList.length &&
          this.clickIndex === 3
        ) {
          this.isLastStep = true;
          playCorrectSound();
          startConfetti();
        }
      } else {
        console.log("diercidianji+++++++");
        if (this.currentIndex === this.gameList.length) {
          if (!this.isClickToShowSentence) {
            this.isLastStep = true;
            playCorrectSound();
            startConfetti();
          } else {
            setTimeout(() => {
              if (this.gameList[index].isShowSentence) {
                this.isLastStep = true;
                playCorrectSound();
                startConfetti();
              }
            }, 1000);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.speaking-sentence-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;

    .bgImg-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .img-box {
        width: 100%;
        height: 100%;
        &.hasPointer {
          cursor: pointer;
        }
        img {
          border-radius: 58px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      min-width: 18%;
      height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      width: fit-content;
      padding: 10px 25px;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .lesson-29,
    .lesson-28 {
      background: #224e96;
    }
    .lesson-1 {
      width: 30%;
      background: #224e96;
    }
    .lesson-30 {
      width: 25%;
    }
    .lesson-32 {
      height: 11%;
      top: 30%;
      padding: 10px 0px;
    }
    .lesson-371 {
      width: 30%;
    }
    .lesson-411 {
      background: #224e96;
      width: 25%;
    }

    .bottom-sentence-area {
      position: absolute;
      width: 100%;
      height: 20%;
      bottom: 4%;
      display: flex;
      justify-content: center;
      align-items: center;
      .hanzi-item {
        // width: 50%;
        margin: 0 auto;
        background: #cd4c3f;
        position: absolute;
        padding: 1% 15%;
        color: #fff;
        border-radius: 40px;
        .sentence-hanzi,
        .sentence-pinyin {
          text-align: center;
        }
      }
      .blueBG {
        background: #224e96;
        // padding: 1% 5%;
      }
      &.higherPosition {
        bottom: 8%;
      }
    }
  }
}

.lesson25-container .bgImg-area {
  cursor: pointer;
}
</style>
